import logo from "../assets/nft/Logo_Cropped.png"
import cw from "../assets/images/CWImage.png"
import white_paper from "../assets/images/white_paper.png"
import discord from "../assets/images/discord.png"
import twitter from "../assets/images/twitter.png"

import {NavLink, useLocation} from "react-router-dom"
import ConnectWallet from "../components/ConnectWallet";
import {useAccount, useBalance, useDisconnect} from "wagmi";
import {useStores} from "../context/StoreContext";
import {useState} from "react";
import {useOwnedTokensDetails, useOwnedV1TokensDetails} from "../context/hooks/MintContractHooks";

const AccountBalance = ({address}: any) => {
    const {data, isError, isLoading} = useBalance({
        address: address,
    })
    return <>{parseInt(`${parseFloat(`${data?.formatted}`) * 1000}`) / 1000} {data?.symbol}</>
}

const WalletConnectCW = () => {
    const {address} = useAccount();
    const [onHover, setOnHover] = useState(false);
    const {disconnect} = useDisconnect();
    return <div
        className={"flex flex-row-reverse items-center justify-center space-x-4"}
        onClick={() => {
            if (address) {
                disconnect()
            }
        }}
        onMouseOver={() => setOnHover(true)}
        onMouseOut={() => setOnHover(false)}
        onBlur={() => {
            setOnHover(false)
        }}>
        {!address && <ConnectWallet>
            {/*{!onHover && <img className="w-16 cursor-pointer" src={cw} alt="cw"/>}*/}
            {/*{onHover && */}
            <div className={" button-gold py-3"}>
                <span className={"text-gold text-sm"}>CONNECT WALLET</span>
            </div>
            {/*}*/}
        </ConnectWallet>}
        {address && <div className={" button-gold py-3 w-48 md:w-64 text-center"}>
            {!onHover && <>

                {/*<span className={"text-gold text-sm"}>{*/}
                {/*    address.slice(0, 8) + "..." + address.slice(address.length - 4, address.length)*/}
                {/*}</span>*/}
                {address && <>
                    <span className={"text-gold text-base"}> <AccountBalance address={address}/></span>
                    <span className={"hidden md:inline"}> / </span>
                    <span className={"hidden md:inline text-gold text-sm"}>{
                        address.slice(0, 6) + "..." + address.slice(address.length - 4, address.length)
                    }
        </span>


                </>}
            </>
            }
            {onHover && <span className={"text-gold"}>DISCONNECT</span>}
        </div>}

    </div>
}

interface Props {
    children: JSX.Element,
    showMenu?: boolean,
};

const MenuItems = ({showMenu}: any) => {
    const {pathname} = useLocation();
    const {uiStore} = useStores();
    const {address} = useAccount();
    const {ownedTokens} = useOwnedTokensDetails();
    const ownedTokenListV1 = useOwnedV1TokensDetails();
    return (
        <>
            <div className={"flex flex-row justify-center"}>
                <ul className=" flex space-x-8 text-xl">

                    {(showMenu && address) &&
                        <>
                            <li><NavLink
                                className={`menu-text font-normal ${pathname === "/" && !uiStore.isVideoPlayed ? "menu-active" : "menu"}`}
                                // className={({isActive}) => ((isActive && !uiStore.isVideoPlayed) ? "menu" : "active:menu")}
                                to="/trailer" onClick={() => {
                                uiStore.setIsVideoPlayed(false);
                            }}>TRAILER</NavLink></li>
                            <li><NavLink
                                className={`menu-text menu font-normal ${pathname === "/" && uiStore.isVideoPlayed ? "menu-active" : "menu"}`}
                                // className={({isActive}) => (isActive ? "menu" : "no-underline")}
                                to="/">MINT</NavLink></li>
                            {/*<li><NavLink*/}
                            {/*    className={`menu-text menu font-normal ${pathname === "/ticket" && uiStore.isVideoPlayed ? "menu-active" : "menu"}`}*/}
                            {/*    // className={({isActive}) => (isActive ? "menu" : "no-underline")}*/}
                            {/*    to="/ticket">LOTTERY</NavLink></li>*/}

                            {(ownedTokens && ownedTokens.tokens.length > 0) && <li><NavLink to="/app"
                                                                                            className={`menu-text menu font-normal ${pathname === "/app" ? "menu-active" : "menu"}`}
                                // className={({isActive}) => (isActive ? "underline" : "no-underline")}
                            >DAPP</NavLink>
                            </li>}
                            {(ownedTokenListV1 && ownedTokenListV1.tokens.length > 0) && <li><NavLink to="/swap"
                                                                                                      className={`menu-text menu font-normal
                                                                                         ${pathname === "/swap" ? "menu-active" : "menu"}`}
                                // className={({isActive}) => (isActive ? "underline" : "no-underline")}
                            >SWAP</NavLink>
                            </li>}
                        </>
                    }
                </ul>
            </div>
        </>
    );
}

const TemplatePage = ({children, showMenu}: Props) => {
    const {address} = useAccount();

    return <div className="h-screen flex flex-col justify-between">
        <div className={"w-full"}>
            <div className={"container mx-auto"}>
                <div className="flex justify-items-center  items-center py-4 px-4 md:px-0 ">
                    <div className={"flex-1"}><img className="w-20" src={logo} alt="logo"/></div>
                    <div className={"flex-1  hidden md:inline "}>
                        <MenuItems showMenu={showMenu}/>
                    </div>
                    <div className={"flex-1 flex flex-row items-end justify-end"}><WalletConnectCW/></div>
                </div>
                {/*<div className={"gold-separator w-full"}></div>*/}
                <div className={"w-full flex flex-row items-center justify-center md:hidden"}>
                    <MenuItems showMenu={showMenu}/>
                </div>
            </div>

            <div className={"w-full gold-separator"} style={{
                height: "0.08em",
            }}>

            </div>
        </div>


        <div className="container mx-auto">
            <ConnectWallet showModel={!address}>
                <></>
            </ConnectWallet>
            <div className="z-0">{children}</div>
        </div>
        <div className="bg-[#0D1216] py-4 static bottom-0 left-[50%]">
            <div className=" flex justify-center space-x-8">
                <a target={"_blank"} href={"https://docs.innercircle.finance"}> <img src={white_paper}
                                                                                     alt="white-paper"/></a>
                <a target={"_blank"} href={"https://twitter.com/CircleFounders"}><img src={twitter} alt="twitter"/></a>
                <a target={"_blank"} href={"https://discord.gg/theinnercircle"}><img src={discord} alt="discord"/></a>
            </div>
        </div>
    </div>
}

export default TemplatePage;