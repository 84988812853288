import MintTokenV1 from "contract/InnerCircleNFT.json";
import MintToken from "contract/InnerCircleNFTV2.json";
import {TokenType} from "../data/TokenData";

const TOKEN_ADDRESS = `${process.env.REACT_APP_TOKEN_ADDRESS}`;
export const MINT_PLATINUM_ADDRESS = `${process.env.REACT_APP_MINT_PLATINUM_ADDRESS}`;
export const MINT_GOLD_ADDRESS = `${process.env.REACT_APP_MINT_GOLD_ADDRESS}`;
export const MINT_SILVER_ADDRESS = `${process.env.REACT_APP_MINT_SILVER_ADDRESS}`;
export const MINT_BRONZE_ADDRESS = `${process.env.REACT_APP_MINT_BRONZE_ADDRESS}`;

// export const TokenBasicConfig = {
//     address: MINT_ADDRESS,
//     abi: MintToken.abi
// }
export const ContractBasicConfigPlatinum = {
    address: MINT_PLATINUM_ADDRESS,
    abi: MintToken.abi
}
export const ContractBasicConfigGold = {
    address: MINT_GOLD_ADDRESS,
    abi: MintToken.abi
}
export const ContractBasicConfigSilver = {
    address: MINT_SILVER_ADDRESS,
    abi: MintToken.abi
}
export const ContractBasicConfigBronze = {
    address: MINT_BRONZE_ADDRESS,
    abi: MintToken.abi
}

export const getContractBasicConfig = (tokenType: TokenType): any => {
    switch (tokenType) {
        case TokenType.PLATINUM:
            return ContractBasicConfigPlatinum;
        case TokenType.GOLD:
            return ContractBasicConfigGold;
        case TokenType.SILVER:
            return ContractBasicConfigSilver;
        case TokenType.BRONZE:
            return ContractBasicConfigBronze;
        default:
            return {};
    }
}

//V1 Contract
export const  MINT_V1_PLATINUM_ADDRESS = `${process.env.REACT_APP_MINT_V1_PLATINUM_ADDRESS}`;
export const  MINT_V1_GOLD_ADDRESS = `${process.env.REACT_APP_MINT_V1_GOLD_ADDRESS}`;
export const  MINT_V1_SILVER_ADDRESS = `${process.env.REACT_APP_MINT_V1_SILVER_ADDRESS}`;
export const  MINT_V1_BRONZE_ADDRESS = `${process.env.REACT_APP_MINT_V1_BRONZE_ADDRESS}`;

export const ContractV1BasicConfigPlatinum = {
    address: MINT_V1_PLATINUM_ADDRESS,
    abi: MintTokenV1.abi
}
export const ContractV1BasicConfigGold = {
    address: MINT_V1_GOLD_ADDRESS,
    abi: MintTokenV1.abi
}
export const ContractV1BasicConfigSilver = {
    address: MINT_V1_SILVER_ADDRESS,
    abi: MintTokenV1.abi
}
export const ContractV1BasicConfigBronze = {
    address: MINT_V1_BRONZE_ADDRESS,
    abi: MintTokenV1.abi
}

export const getContractV1BasicConfig = (tokenType: TokenType): any => {
    switch (tokenType) {
        case TokenType.PLATINUM:
            return ContractV1BasicConfigPlatinum;
        case TokenType.GOLD:
            return ContractV1BasicConfigGold;
        case TokenType.SILVER:
            return ContractV1BasicConfigSilver;
        case TokenType.BRONZE:
            return ContractV1BasicConfigBronze;
        default:
            return {};
    }
}

// export const MintContract = (signer: Signer) => {
//     return new ethers.Contract(MINT_ADDRESS, MintToken.abi, signer);
// };

// const TokenContract = (signer: Signer) => {
//     return new ethers.Contract(TOKEN_ADDRESS, SimpleToken.abi, signer);
// };


// export const Mint = async (signer: Signer, address: any, type: TokenType, numberOfTokens: number) => {
//     const tx = await MintContract(signer).mint(address, type, numberOfTokens);
//     await tx.wait();
// }