import {Route, Routes, useNavigate} from "react-router-dom";
import MintPage from "pages/MintPage";
import DappPage from "pages/DappPage";
import {observer} from "mobx-react";
import {useStores} from "../context/StoreContext";
import VideoPage from "../pages/VideoPage";
import {useAccount, useSwitchNetwork} from "wagmi";
import HomePage from "../pages/HomePage";
import ConnectedDapp from "../pages/Scene4ConnectedDapp";
import {useEffect} from "react";
import SwapPage from "../pages/SwapPage";

const networkID = `${process.env.REACT_APP_NETWORK_ID}`;
const PageController = observer(() => {
    const {uiStore} = useStores();
    const {address} = useAccount();
    console.log("address", address);
    console.log("uiStore.isCountDownFinished", uiStore.isCountDownFinished);
    return (
        <>
            <Routes>
                <Route path="/trailer" element={<VideoPage/>}/>
                {!address && <Route path="/" element={<HomePage/>}/>}
                {uiStore.isCountDownFinished && address && <>
                    <Route path="/" element={<MintPage/>}/>
                    <Route path="/app" element={<DappPage/>}/>
                    <Route path="/connect" element={<ConnectedDapp/>}/>
                    {/*<Route path="/ticket" element={<TicketPage/>}/>*/}
                    <Route path="/swap" element={<SwapPage/>}/>
                </>}
            </Routes>
        </>
    );
})

const AuthRoutes = () => {
    const {uiStore} = useStores();
    const navigator = useNavigate();
    const {switchNetwork} = useSwitchNetwork({
        chainId: parseInt(networkID),
    })
    useEffect(() => {
        if (switchNetwork && uiStore.isCountDownFinished) {
            switchNetwork(parseInt(networkID))
        }
    }, [switchNetwork, uiStore.isCountDownFinished])

    return (
        <>
            <PageController/>
        </>
    );
}
export default observer(AuthRoutes);