import {makeAutoObservable} from "mobx";
import {MintStore} from "./MintStore";
import AccountStore from "./AccountStore";
import UIStore from "./UIStore";

class RootStore {

    uiStore: UIStore;
    accountStore: AccountStore;
    mintStore: MintStore;

    constructor() {
        this.uiStore = new UIStore(this);
        this.accountStore = new AccountStore(this);
        this.mintStore = new MintStore(this);
        makeAutoObservable(this);
    }

}

export default RootStore;