import {useEffect, useState} from "react";
import {TokenType} from "../../context/data/TokenData";
import {useGetAvailableCount, useMintContract} from "../../context/hooks/MintContractHooks";
import {useStores} from "../../context/StoreContext";
import {observer} from "mobx-react";

interface Props {
    type: TokenType
    onChangeCount: (count: number) => void,
    insufficientBalance?: boolean
}

const MintButton = ({type, onChangeCount, insufficientBalance = false}: Props) => {
    const {availableMint} = useGetAvailableCount({type})
    const [numberOfNfts, setNumberOfNfts] = useState(1)
    const {mint} = useMintContract()
    const {mintStore} = useStores();

    const increment = () => {
        if (numberOfNfts < availableMint) {
            setNumberOfNfts(numberOfNfts + 1)
        }
    }
    const decrement = () => {
        if (numberOfNfts > 1) {
            setNumberOfNfts(numberOfNfts - 1)
        }
    }
    useEffect(() => {
        onChangeCount(numberOfNfts)
    }, [numberOfNfts])

    const getMintButtonTextCss = () => {
        switch (type) {
            case TokenType.PLATINUM:
                return "text-platinum-mint"
            case TokenType.GOLD:
                return "text-gold-mint"
            case TokenType.SILVER:
                return "text-silver-mint"
            case TokenType.BRONZE:
                return "text-bronze-mint"
        }
    }

    const getMintButtonShadowCss = () => {
        switch (type) {
            case TokenType.PLATINUM:
                return "shadow-platinum"
            case TokenType.GOLD:
                return "shadow-gold"
            case TokenType.SILVER:
                return "shadow-silver"
            case TokenType.BRONZE:
                return "shadow-bronze"
        }
    }


    return (<>
        <div
            className={`flex flex-row justify-between py-1 px-4 w-1/3 font-semibold bg-transparent  text-center w-36 ${getMintButtonTextCss()} ${getMintButtonShadowCss()}`}>
            <button className={"text-gold"} onClick={decrement}>-</button>
            <span className="text-center">
                <input
                    className={"w-16 text-reset bg-transparent text-gold focus-visible:outline-amber-300"}
                    value={numberOfNfts}
                    onChange={(v) => {
                        let val = numberOfNfts;
                        try {
                            val = parseInt(v.target.value);
                        } finally {
                            if (isNaN(val)) {
                                setNumberOfNfts(1)
                            } else if (val <= availableMint) {
                                setNumberOfNfts(val)
                            } else {
                                setNumberOfNfts(availableMint)
                            }
                        }
                    }}/>
            </span>
            <button className={"text-gold"} onClick={increment}>+</button>
        </div>
        <button
            onClick={async () => {
                if(!insufficientBalance){
                    await mint(type, numberOfNfts);
                }
            }}
            disabled={mintStore.isMinting !== TokenType.NONE || insufficientBalance}
            className={`text-center border-2 rounded-full py-1 px-12 font-bold ${getMintButtonTextCss()} ${getMintButtonShadowCss()} 
            ${mintStore.isMinting === type ? "disabled:opacity-95" : "disabled:opacity-40"}
            `}>
            <span className={"text-reset text-gold"}>
                {/*{insufficientBalance ? "Insufficient Balance" : mintStore.isMinting === type ? "MINTING..." : "MINT"}*/}
                {mintStore.isMinting === type ? <>MINTING</> : <>MINT</>}
            </span>
        </button>

    </>)
}
export default observer(MintButton)