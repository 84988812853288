export const PROJECT_ID = `${process.env.REACT_APP_IPFS_PROJECT_ID}`;
export const PROJECT_SECRET = `${process.env.REACT_APP_IPFS_PROJECT_KEY}`;
export const IPFS_URL = `${process.env.REACT_APP_IPFS_URL}`;

console.log('IPFS_URL', IPFS_URL);
console.log('PROJECT_ID', PROJECT_ID);
console.log('PROJECT_SECRET', PROJECT_SECRET);

export const UploadIPFS = async (blob: Blob, name: string) => {
  const metaDataFormData = new FormData();
  metaDataFormData.append("file", blob, name);
  const metaDataUploadResp = await fetch("https://ipfs.infura.io:5001/api/v0/add", {
    method: "POST",
    headers: {
      // "Content-Type": "multipart/form-data",
      "Accept": "*/*",
      "Authorization": "Basic " + btoa(PROJECT_ID + ":" + PROJECT_SECRET)
    },
    body: metaDataFormData
  });
  const resp = await metaDataUploadResp.json();
  return resp.Hash;
};

export const GetIPFS = async (hash: string) => {
  const path = `${IPFS_URL}/${hash}`;
  const resp = await fetch(path);
  return resp.json();
};

export const GetIPFSBaseURL = (hash: string) => {
  return `${IPFS_URL}/${hash}`;
};