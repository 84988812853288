import {getKeyImage, getKeyName, TokenType} from "../../context/data/TokenData";
import MintButton from "../Mint";
import {useEffect, useState} from "react";
import ConnectWallet from "../ConnectWallet";
import {useAccount, useBalance} from "wagmi";
import {
    useGetAvailableCount,
    useMintListener,
    useNFTTypeCROPrice,
    useNFTTypePrice
} from "../../context/hooks/MintContractHooks";
import {BigNumber} from "ethers";
import {ethers} from "ethers/lib.esm";

interface Props {
    tokenType: TokenType,
}

const KeyCard = ({tokenType}: Props) => {
    const {availableMint} = useGetAvailableCount({
        type: tokenType, forceUpdate: () => {
        }
    })
    const {price} = useNFTTypePrice({type: tokenType})
    const {croPrice} = useNFTTypeCROPrice({type: tokenType})
    const {address} = useAccount()
    const [soldOut, setSoldOut] = useState(false)
    const [numberOfNfts, setNumberOfNfts] = useState(1)
    const [nftUsdPrice, setNftUsdPrice] = useState(0)
    const [nftCroPrice, setNftCroPrice] = useState(0)
    const [isInsufficient, setIsInsufficient] = useState(false)
    const {data, isError, isLoading} = useBalance({
        address: address,
    })

    useEffect(() => {
        if (availableMint > 0) {
            setNftCroPrice(croPrice * numberOfNfts)
            setNftUsdPrice(price * numberOfNfts)
            setSoldOut(false)
        } else if (availableMint === 0) {
            setSoldOut(true)
            setNftCroPrice(0)
            setNftUsdPrice(0)
        }
    }, [numberOfNfts, croPrice, price, availableMint])

    useEffect(() => {
        if (data && data?.value && nftCroPrice) {
            parseFloat(ethers.utils.formatEther(data?.value)) < nftCroPrice ? setIsInsufficient(true) : setIsInsufficient(false)
            // console.log(data?.value.toNumber(),nftCroPrice)
            // let total = BigNumber.from(nftCroPrice).mul(BigNumber.from(numberOfNfts));
            // let isInsufficient = data.value.lt(total)
            // console.log(data.value.toString(), nftCroPrice)
        }
    }, [data,nftCroPrice])

    return (
        <div className={`flex flex-col justify-center items-center space-y-6  ${soldOut?'scale-90 opacity-60':''}`}>
            <h2 className={"text-gold text-2xl"}>{getKeyName(tokenType)} KEY</h2>
            <div
                className={`flex flex-col justify-center items-center  w-full  component-shadow component-shadow-${`${getKeyName(tokenType)}`.toLowerCase()}`}>
                <img className="rounded-lg" src={getKeyImage(tokenType, soldOut)}
                     alt="platinum"/>
            </div>
            <div className="flex flex-row justify-between  w-full  space-x-4 text-lg">
                {!soldOut ?
                    <>
                        {!address && <>
                            <ConnectWallet>
                                <button className='button-gold w-full'>Connect</button>
                            </ConnectWallet>
                        </>}
                        {address && <MintButton insufficientBalance={isInsufficient} onChangeCount={(val) => {
                            setNumberOfNfts(val)
                        }} type={tokenType}/>}
                    </>
                    : <div
                        className=" w-full border-2 rounded-full py-1 px-12 text-red-500 font-semibold text-center">
                        SOLD OUT
                    </div>}
            </div>

            <div className="flex flex-row justify-between  w-full text-gold justify-between space-x-4 text-xl">
                <span
                    className={"text-base"}>${parseInt(`${nftUsdPrice * 100}`) / 100} / {parseInt(`${nftCroPrice * 100}`) / 100} CRO</span>
                <span className={"text-base"}>{availableMint} REMAINING</span>
            </div>
        </div>
    )
}

export default KeyCard