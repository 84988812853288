import RootStore from "./RootStore";
import {action, makeAutoObservable, observable} from "mobx";
import {TokenType} from "../data/TokenData";
import AccountStore from "./AccountStore";

export class MintStore {

    private accountStore: AccountStore;

    @observable
    isMinting:TokenType = TokenType.NONE;

    constructor(private rootStore: RootStore) {
        this.accountStore = rootStore.accountStore;
        makeAutoObservable(this);
    }

    @action
    async mint(numberOfTokens: number, tokenType: TokenType) {
        // await Mint(numberOfTokens, tokenType);
    }


    @action
    setIsMinting(_status: TokenType) {
        this.isMinting = _status;
    }
}