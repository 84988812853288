import RootStore from "./RootStore";
import {action, autorun, makeAutoObservable, observable} from "mobx";

export default class UIStore {

    @observable
    public simpleValue: string = "simple value";

    @observable
    isVideoPlayed: boolean = false;
    isCountDownFinished: boolean = false;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        autorun(() => {
            localStorage.getItem("isVideoPlayed") && this.setIsVideoPlayed(JSON.parse(localStorage.getItem("isVideoPlayed") || "false"));
            localStorage.getItem("isCountDownFinished") && this.setCountDownFinished(JSON.parse(localStorage.getItem("isCountDownFinished") || "false"));
        })
    }


    @action
    setSimpleValue(value: string) {
        this.simpleValue = value;
    }

    @action
    setIsVideoPlayed(value: boolean) {
        this.isVideoPlayed = value;
        localStorage.setItem("isVideoPlayed", JSON.stringify(value));
    }

    @action
    setCountDownFinished(value: boolean) {
        this.isCountDownFinished = value;
        localStorage.setItem("isCountDownFinished", JSON.stringify(value));
    }
}