import {Dialog} from "primereact/dialog";
import logo from "../../assets/nft/Logo_Cropped.png";
import {ReactNode, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useAccount, useConnect, useDisconnect} from 'wagmi'
import {InjectedConnector} from 'wagmi/connectors/injected'
import metamask from "../../assets/images/metamask.png";
import wallet from "../../assets/images/walletconnect.png";
import coinbase from "../../assets/images/coinbase.png";
import crypto from "../../assets/images/crypto.png";
import {useStores} from "../../context/StoreContext";


interface IWalletButton {
    onSelectWallet: () => void
    name: string
    image: string
}

const WalletButton = ({name, image, onSelectWallet}: IWalletButton) => {
    return (
        <div
            onClick={onSelectWallet}
            className=" space-x-2 border rounded-full button-gold flex justify-center items-center py-3  w-full">
            <img className=" w-6" src={image} alt="metamask"/>
            <span className={"text-gold"}>{name}</span>
        </div>
    )
}

const SelectWalletComponent = () => {
    const {uiStore} = useStores();
    const {connect, connectors, isSuccess, isError, error} = useConnect({
        connector: new InjectedConnector(),
        onSuccess: () => {
            console.log("success")
            // uiStore.setIsVideoPlayed(false);
        }
    });

    useEffect(() => {
        console.log("isSuccess", connectors)
    }, [isSuccess, isError, error])

    const getConnector = (name: string) => {
        return connectors.find((connector) => connector.name === name)
    }

    return (
        <div className={"flex flex-col space-y-4 mt-8"}>
            {/*<p className=" text-xl text-gold">CONNECT TO THE INNER CIRCLE</p>*/}

            <WalletButton
                name={"METAMASK"}
                image={metamask}
                onSelectWallet={() => connect({connector: getConnector('MetaMask')})}
            />

            <WalletButton
                name={"CRYPTO.COM DeFi Wallet"}
                image={crypto}
                onSelectWallet={() => connect({connector: getConnector('Injected')})}
            />
            <WalletButton
                name={"COINBASE"}
                image={coinbase}
                onSelectWallet={() => connect({connector: getConnector('coinbaseWallet')})}
            />
            <WalletButton
                name={"WALLETCONNECT"}
                image={wallet}
                onSelectWallet={() => connect({connector: getConnector('WalletConnect')})}
            />
        </div>
    );
}

interface IWalletConnectButton {
    onSelectWallets: () => void,
}

const WalletConnectButton = ({onSelectWallets}: IWalletConnectButton) => {
    return (
        <>

            {/*<p className=' text-sm text-[#C1A875]'>CONNECT A WALLET TO CONTINUE</p>*/}
            <a
                onClick={() => {
                    onSelectWallets()
                }}
                className='button-gold'>+
                CONNECT WALLET
            </a>
        </>
    );
}

interface Props {
    children: ReactNode,
    showModel?: boolean,
    onConnect?: () => void,
}

const ConnectWallet = ({children, onConnect}: Props) => {
    const {address} = useAccount()
    const {uiStore} = useStores();
    const {disconnect} = useDisconnect()
    const [showWallets, setShowWallets] = useState(true)
    const [displayWelcomePopup, setDisplayWelcomePopup] = useState(false)
    const onHide = () => {
        setDisplayWelcomePopup(false)
        setShowWallets(false)
    }
    if (!uiStore.isCountDownFinished) {
        return (<> </>);
    }
    return (<>
        <div className={"w-full"} onClick={() => {
            setDisplayWelcomePopup(true)
            if (onConnect) {
                onConnect()
            }
        }}>
            {children}
        </div>
        <Dialog visible={displayWelcomePopup} modal
                style={{border: '3px solid #C1A875', 'border-radius': "12px"}}
                className={!address ? "h-fit w-fit md:w-fit md:h-fit" : ""}
                contentStyle={{backgroundColor: '#0D1216'}}
                headerStyle={{backgroundColor: '#0D1216', height: "10px"}} onHide={onHide}>
            {!address && <>
                <div className='flex flex-col items-center'>
                    <img className='w-16' src={logo} alt="logo"/>
                    <p className='text-gold'>WELCOME TO THE INNER CIRCLE</p>
                </div>
                <div className=' flex flex-col justify-center items-center space-y-4 h-4/6'>

                    <div className={"py-2"}>

                        <SelectWalletComponent/>
                        {/*<WalletConnectButton onSelectWallets={() => {*/}
                        {/*    setShowWallets(true)*/}
                        {/*}}/>*/}
                        {/*{showWallets ?*/}
                        {/*    <></> : <WalletConnectButton onSelectWallets={() => {*/}
                        {/*        setShowWallets(true)*/}
                        {/*    }}/>}*/}

                        {/*{showWallets && <SelectWalletComponent/>}*/}

                    </div>
                </div>
            </>
            }
            {address && <>
                <div className={"flex flex-col space-y-4 mt-4"}>
                    {/*<p className=" text-xl text-gold mt-4">CONNECT TO THE INNER CIRCLE</p>*/}
                    <div className={"flex flex-col space-y-2 justify-center items-center"}>

                        {/*<span className={"text-gold text-center"}>{address}</span>*/}
                        <a
                            onClick={() => {
                                disconnect()
                            }}
                            className='button-gold-normal'> DISCONNECT WALLET
                        </a>
                    </div>
                </div>
            </>}

        </Dialog>
    </>)
}
export default observer(ConnectWallet)