import React, {useEffect, useState} from "react";

import TemplatePage from "./TemplatePage";
import {DistributionModal} from "context/data";

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import HeldKeyCard from "../components/HeldKeyCard";
import {TokenDetail, TokenType} from "../context/data/TokenData";
import {
    useDistributorsAddress,
    useDistributorsValueOriginal,
    useOwnedTokensDetails
} from "../context/hooks/MintContractHooks";
import {getHolderName, HolderType} from "../context/data/HolderData";


const DappPage = () => {
    const [walletDistribution, setWalletDistribution] = useState<DistributionModal[]>([]);
    const {treasury, vault, marketing, team} = useDistributorsAddress();
    const {treasuryAmount, vaultAmount, marketingAmount, teamAmount} = useDistributorsValueOriginal();
    const {ownedTokens} = useOwnedTokensDetails();
    const [tokens, setTokens] = useState<TokenDetail[]>([]);
    const [walletShare, setWalletShare] = useState(0);
    useEffect(() => {
        if (ownedTokens && ownedTokens.tokens && ownedTokens.tokens.length > 0) {
            setTokens([...ownedTokens.tokens])
        }
    }, [ownedTokens])

    useEffect(() => {
        if (treasury && vault && marketing && team) {

            const platinum = tokens.filter((token) => token.tokenType === TokenType.PLATINUM).length;
            const gold = tokens.filter((token) => token.tokenType === TokenType.GOLD).length;
            const silver = tokens.filter((token) => token.tokenType === TokenType.SILVER).length;
            const bronze = tokens.filter((token) => token.tokenType === TokenType.BRONZE).length;

            const [bronzeConst, silverConst, goldConst, platinumConst] = [0.000078, 0.000197, 0.000402, 0.000831];

            const getWalletShare = (amount: number) => {
                const A = (amount * bronzeConst) * bronze;
                const B = (amount * silverConst) * silver;
                const C = (amount * goldConst) * gold;
                const D = (amount * platinumConst) * platinum;
                const total = A + B + C + D;
                return parseInt(`${parseFloat(total.toString()) * 100}`) / 100;
            }

            const treasuryAmountString = parseInt(`${treasuryAmount * 100}`) / 100;
            const vaultAmountString = parseInt(`${vaultAmount * 100}`) / 100;
            const marketingAmountString = parseInt(`${marketingAmount * 100}`) / 100;

            setWalletShare(getWalletShare(vaultAmount));

            setWalletDistribution([
                {
                    wallet: getHolderName(HolderType.VAULT),
                    address: `${vault}`,
                    value: `$${vaultAmountString}`,
                    // share: `$${getWalletShare(vaultAmount)}`
                },
                {
                    wallet: getHolderName(HolderType.TREASURY),
                    address: `${treasury}`,
                    value: `$${treasuryAmountString}`,
                    // share: `$${getWalletShare(treasuryAmount)}`
                },
                {
                    wallet: getHolderName(HolderType.MARKETING),
                    address: `${marketing}`,
                    value: `$${marketingAmountString}`,
                    // share: `$${getWalletShare(marketingAmount)}`
                },
            ])
        }


    }, [treasury, vault, marketing, team, treasuryAmount, vaultAmount, marketingAmount, teamAmount, tokens]);


    const [pieChartData, setPieCharData] = useState({});


    useEffect(() => {
        setPieCharData({
            labels: ["VAULT", "TREASURY", "MARKETING"],
            datasets: [
                {
                    data: [vaultAmount, treasuryAmount, marketingAmount],
                    backgroundColor: ["#e3e3e3", "#fc992d", "#eedb42"],
                    borderColor: '#000',
                },
            ],
        })
    }, [vaultAmount, treasuryAmount, marketingAmount]);

    const [lightOptions] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#D1A956',
                    data: true
                },
                position: "right",
            },
        },
        layout: {
            padding: {
                top: 50,
            }
        },
        maintainAspectRatio: false,
    });

    return (
        <TemplatePage showMenu={true}>
            <div className="  ">
                <div className=" mt-10 px-6 py-6  space-y-4 rounded-xl shadow-inner shadow-[#4f432c]">
                    <p className=" text-xl font-bold text-2xl  text-[#C1A875]">NFTS HELD</p>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 items-center">

                        {ownedTokens && <>
                            <HeldKeyCard totalMinted={ownedTokens.tokenCount(TokenType.PLATINUM)}
                                         totalUsdValue={ownedTokens.tokenCost(TokenType.PLATINUM)}
                                         tokenType={TokenType.PLATINUM}/>
                            <HeldKeyCard totalMinted={ownedTokens.tokenCount(TokenType.GOLD)}
                                         totalUsdValue={ownedTokens.tokenCost(TokenType.GOLD)}
                                         tokenType={TokenType.GOLD}/>
                            <HeldKeyCard totalMinted={ownedTokens.tokenCount(TokenType.SILVER)}
                                         totalUsdValue={ownedTokens.tokenCost(TokenType.SILVER)}
                                         tokenType={TokenType.SILVER}/>
                            <HeldKeyCard totalMinted={ownedTokens.tokenCount(TokenType.BRONZE)}
                                         totalUsdValue={ownedTokens.tokenCost(TokenType.BRONZE)}
                                         tokenType={TokenType.BRONZE}/>

                        </>}
                    </div>
                </div>

                <div className="grid grid-cols-4 gap-4 pt-4 px-6 rounded-xl shadow-inner shadow-[#4f432c] mb-20 mt-8">
                    <p className="col-span-4 text-[#C1A875] text-2xl font-semibold">
                        WALLET DISTRIBUTION / YOUR HOLDINGS
                    </p>
                    <div className={"col-span-4 md:col-span-3"}>
                        <DataTable
                            className={"w-full"}
                            responsiveLayout="scroll"
                            value={walletDistribution}
                            tableStyle={{borderBottom: " 1px #C1A875"}}
                        >
                            <Column
                                field="wallet"
                                header="WALLET"
                                headerStyle={{backgroundColor: "transparent", color: "#C1A875"}}
                                style={{
                                    backgroundColor: "#1E1E1E",
                                    color: "#C1A875",
                                    borderBottom: " 2px solid #C1A875",
                                }}
                            ></Column>
                            <Column
                                field="address"
                                header="ADDRESS"
                                body={(rowData) => (
                                    <div className="flex flex-row justify-start items-start">
                                        <a href={`${process.env.REACT_APP_SCAN_URL}/${rowData.address}`}
                                           target={"_blank"}
                                           className={"flex flex-row items-start justify-start space-x-2"}>
                                            <p className="text-[#C1A875]">{rowData.address.slice(0, 6)}...{rowData.address.slice(-4)}</p>
                                            <i className="pi pi-external-link" style={{'fontSize': '1em'}}></i>
                                        </a>
                                    </div>
                                )}
                                headerStyle={{backgroundColor: "transparent", color: "#C1A875"}}
                                style={{
                                    backgroundColor: "#1E1E1E",
                                    color: "#C1A875",
                                    borderBottom: " 2px solid #C1A875",
                                }}
                            ></Column>
                            <Column
                                field="value"
                                header="VALUE"
                                headerStyle={{backgroundColor: "transparent", color: "#C1A875"}}
                                style={{
                                    backgroundColor: "#1E1E1E",
                                    color: "#C1A875",
                                    borderBottom: " 2px solid #C1A875",
                                }}
                            ></Column>
                        </DataTable>
                    </div>
                    <div className={"col-span-4 md:col-span-1 flex flex-col justify-center items-center"}>
                        <div
                            className={"w-full md:w-48 h-48 flex flex-col space-y-2 justify-center items-center shadow-inner shadow-[#4f432c] rounded-md"}>
                            <span className={"text-gold text-2xl"}>
                                ${walletShare}
                            </span>
                            <span className={"text-gold text-base"}>
                                YOUR SHARE
                            </span>
                        </div>
                    </div>
                    <div className={"pt-8 pb-2 col-span-4"}>
                        <div className="gold-separator h-0.5 w-full"></div>
                    </div>
                </div>
            </div>
        </TemplatePage>
    );
};

export default DappPage;
