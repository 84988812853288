import TemplatePage from "./TemplatePage";
import dots from "../assets/images/dots.png"
import bgImage from "../assets/images/Logo_BG.png"
import {useCountdown} from "../components/CountDown";
import ConnectWallet from "../components/ConnectWallet";
import {useEffect, useState} from "react";
import {useStores} from "../context/StoreContext";
import {useNavigate} from "react-router-dom";

const HomePage = () => {
    const navigator = useNavigate();
    const [days, hours, minutes, seconds] = useCountdown("2022-12-13T18:12:00Z");
    const [isFinished, setIsFinished] = useState<boolean>();
    const {uiStore} = useStores();
    useEffect(() => {
        if (!isFinished && days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
            setIsFinished(true);
            uiStore.setCountDownFinished(true);
        }
    }, [days, hours, minutes, seconds, uiStore.isVideoPlayed])
    useEffect(() => {
        // console.log("uiStore.isCountDownFinished", uiStore.isCountDownFinished);
        // console.log("uiStore.isVideoPlayed", uiStore.isVideoPlayed);
        if (uiStore.isCountDownFinished && !uiStore.isVideoPlayed) {
            navigator("/trailer");
        }
        setIsFinished(uiStore.isCountDownFinished);
    }, [uiStore.isCountDownFinished, uiStore.isVideoPlayed])


    return (
        <TemplatePage showMenu={false}>
            <>
                <div className=" flex flex-col items-center justify-center space-y-4 w-full h-full">
                    <div
                        className={"border border-black flex flex-row justify-center items-center border-opacity-50 px-12 py-12 rounded-full " +
                            "w-[350px] h-[350px] md:w-[600px] md:h-[600px]"} style={
                        {
                            backgroundImage: `url(${bgImage})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundBlendMode: "color-dodge",
                        }
                    }>
                        <div className={"flex flex-col relative items-center justify-center space-y-4 w-80 h-80"}>
                            {!isFinished && <div className=" flex space-x-2 items-center">
                                <div
                                    className="count-down-box">
                                    <p>{days} D</p>
                                </div>
                                <img className="w-3 h-8 md:h-12" src={dots} alt="dots"/>
                                <div className="count-down-box">
                                    <p>{hours} H</p>
                                </div>
                                <img className="w-3 h-8 md:h-12" src={dots} alt="dots"/>
                                <div
                                    className="count-down-box">
                                    <p>{minutes} M</p>
                                </div>
                                <img className=" w-3 h-8 md:h-12" src={dots} alt="dots"/>
                                <div className="count-down-box">
                                    <p>{seconds} S</p>
                                </div>
                            </div>}

                            {isFinished && <div className="gold-button gold-button-border">
                                <ConnectWallet onConnect={() => {

                                }}>
                                    <span className="text-[#C1A875]">CONNECT TO THE INNER CIRCLE</span>
                                </ConnectWallet>
                            </div>}
                        </div>
                    </div>
                </div>
            </>
        </TemplatePage>
    );
};
export default HomePage;