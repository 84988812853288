export enum HolderType {
    TREASURY = 0,
    VAULT = 1,
    MARKETING = 2,
    TEAM = 3
}

export const getHolderName = (tokenType: HolderType) => {
    switch (tokenType) {
        case HolderType.TREASURY:
            return "TREASURY"
        case HolderType.VAULT:
            return "VAULT"
        case HolderType.MARKETING:
            return "MARKETING"
        case HolderType.TEAM:
            return "TEAM"
    }
}