import {getKeyImage, getKeyName, TokenType} from "../../context/data/TokenData";
import {useNFTTypeTotalMinted} from "../../context/hooks/MintContractHooks";

interface Props {
    tokenType: TokenType,
    totalMinted: number,
    totalUsdValue: number,
}

const HeldKeyCard = ({tokenType, totalMinted, totalUsdValue}: Props) => {

    // const {totalMinted, totalUsdValue, totalCroValue} = useNFTTypeTotalMinted({type: tokenType})


    return (
        <>
            <div className="p-4 space-y-2" style={{
                width: "90%",
            }}>
                <img className="w-full rounded-lg shadow-inner shadow-[#4f432c]  " style={{
                }} src={getKeyImage(tokenType)}
                     alt="gold"/>
                <p className="text-gold">{getKeyName(tokenType)} KEY</p>
                <div className="text-gold flex justify-center">
                    {/*<p className=" font-semibold">${totalUsdValue}</p>*/}
                    <p>{totalMinted} HELD</p>
                </div>
            </div>
        </>
    );
}

export default HeldKeyCard;