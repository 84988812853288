import TemplatePage from "./TemplatePage";
import {getKeyName, TokenType} from "../context/data/TokenData";
import {useEffect, useState} from "react";
import {
    useApprove,
    useIsApprovedForSwap,
    useOwnedV1TokensDetails,
    useSwapV1ToV2
} from "../context/hooks/MintContractHooks";

interface Props {
    tokenType: TokenType,
    action: () => void
    balance?: number
}

const SwapButton = ({tokenType, action, balance}: Props) => {
    const [isProcessing, setIsProcessing] = useState(false)
    const {isApproved} = useIsApprovedForSwap(tokenType);
    const swapFn = useSwapV1ToV2(tokenType);
    return (
        <button
            onClick={async () => {
                setIsProcessing(true);
                try {
                    const {swap} = await swapFn;
                    await swap();
                    setIsProcessing(false);
                    action();
                } finally {
                    setIsProcessing(false);
                }

            }}
            disabled={!isApproved}
            // ${getMintButtonTextCss()} ${getMintButtonShadowCss()}
            className={`text-center border-2 rounded-full py-1 px-12 font-bold w-72
            ${isProcessing ? "disabled:opacity-95" : "disabled:opacity-40"}
            `}>
            <span className={`text-reset ${isApproved ? "text-gold" : "text-gold-disabled"}`}>
                {isProcessing ? <>SWAPPING {getKeyName(tokenType)}</> : <> <span
                    className={"mr-1"}> SWAP </span> {getKeyName(tokenType)} </>}
                {(balance && balance > 0) ? `(${balance})` : ""}
            </span>
        </button>
    );
}
const PermissionButton = ({tokenType, action, balance}: Props) => {
    const [isProcessing, setIsProcessing] = useState(false)
    const {isApproved} = useIsApprovedForSwap(tokenType);
    const approveFn = useApprove(tokenType);
    return (
        <button
            onClick={async () => {
                setIsProcessing(true);
                try {
                    const {approve} = await approveFn;
                    await approve();
                    setIsProcessing(false);
                    action();
                } finally {
                    setIsProcessing(false);
                }

            }}
            disabled={isApproved || balance === 0}
            // ${getMintButtonTextCss()} ${getMintButtonShadowCss()}
            className={`text-center border-2 rounded-full py-1 px-12 font-bold w-72
            ${isProcessing ? "disabled:opacity-95" : "disabled:opacity-40"}
            `}>
            <span className={"text-reset text-gold"}>
                {isProcessing ? <>APPROVING {getKeyName(tokenType)}</> : <> <span
                    className={"mr-1"}> APPROVE </span> {getKeyName(tokenType)} </>}
                {(balance && balance > 0) ? `(${balance})` : ""}
            </span>
        </button>
    );
}

const SwapPage = () => {
    const ownedTokenList = useOwnedV1TokensDetails();
    const [platinumV1Balance, setPlatinumV1Balance] = useState(0);
    const [goldV1Balance, setGoldV1Balance] = useState(0);
    const [silverV1Balance, setSilverV1Balance] = useState(0);
    const [bronzeV1Balance, setBronzeV1Balance] = useState(0);
    useEffect(() => {

        if (ownedTokenList.tokens) {
            const tokens = ownedTokenList.tokens;
            const platinum = tokens.filter((token) => token.tokenType === TokenType.PLATINUM).length;
            const gold = tokens.filter((token) => token.tokenType === TokenType.GOLD).length;
            const silver = tokens.filter((token) => token.tokenType === TokenType.SILVER).length;
            const bronze = tokens.filter((token) => token.tokenType === TokenType.BRONZE).length;
            setPlatinumV1Balance(platinum);
            setGoldV1Balance(gold);
            setSilverV1Balance(silver);
            setBronzeV1Balance(bronze);
        }

    }, [ownedTokenList])
    const refresh = () => window.location.reload()
    return (
        <TemplatePage showMenu={true}>
            <div className="flex space-x-4 flex-col mt-16 w-full px-16 justify-center items-center space-y-4">
                <h1 className={"text-gold"}>only process once or gas fees will be lost.</h1>

                <div className={"flex flex-row space-x-4"}>
                    <PermissionButton tokenType={TokenType.PLATINUM} action={async () => {
                        refresh()
                    }} balance={platinumV1Balance}/>
                    <SwapButton tokenType={TokenType.PLATINUM} action={async () => {
                        refresh()
                    }} balance={platinumV1Balance}/>
                </div>
                <div className={"flex flex-row space-x-4"}>
                    <PermissionButton tokenType={TokenType.GOLD} action={async () => {
                        refresh()
                    }} balance={goldV1Balance}/>
                    <SwapButton tokenType={TokenType.GOLD} action={async () => {
                        refresh()
                    }} balance={goldV1Balance}/>
                </div>
                <div className={"flex flex-row space-x-4"}>
                    <PermissionButton tokenType={TokenType.SILVER} action={async () => {
                        refresh()
                    }} balance={silverV1Balance}/>
                    <SwapButton tokenType={TokenType.SILVER} action={async () => {
                        refresh()
                    }} balance={silverV1Balance}/>
                </div>
                <div className={"flex flex-row space-x-4"}>
                    <PermissionButton tokenType={TokenType.BRONZE} action={async () => {
                        refresh()
                    }} balance={bronzeV1Balance}/>
                    <SwapButton tokenType={TokenType.BRONZE} action={async () => {
                        refresh()
                    }} balance={bronzeV1Balance}/>
                </div>

            </div>
        </TemplatePage>
    )
}

export default SwapPage