import TemplatePage from "./TemplatePage";

import metamask from "../assets/images/metamask.png";
import torus from "../assets/images/torus.png";
import wallet from "../assets/images/walletconnect.png";
import coinbase from "../assets/images/coinbase.png";
import portis from "../assets/images/portis.png";
import crypto from "../assets/images/crypto.png";

const ConnectedDapp = () => {
  return (
    <TemplatePage>
    <div className=" h-screen">
      <div className=" text-[#D1A956] space-y-4 shadow-inner shadow-[#4f432c]  flex flex-col items-center mt-16 p-8 rounded-xl">
        <p className=" text-xl">CONNECT TO INNERCIRCLE</p>
        <div className=" space-x-2 border rounded-full border-[#D1A956] flex justify-center items-center py-2  w-full">
          <img className=" w-6" src={metamask} alt="metamask"/>
          <p>METAMASK</p>
        </div>
        <div className=" space-x-2 border rounded-full border-[#D1A956] flex justify-center items-center py-2  w-full">
            <img className=" w-6" src={torus} alt="torus"/>
            <p>TORUS</p>
        </div>
        <div className=" space-x-2 border rounded-full border-[#D1A956] flex justify-center items-center py-2  w-full">
            <img className=" w-6" src={wallet} alt="walletconnect"/>
            <p>WALLETCONNECT</p>
        </div>
        <div className=" space-x-2 border rounded-full border-[#D1A956] flex justify-center items-center py-2  w-full">
            <img className=" w-6" src={coinbase} alt="coinbase"/>
            <p>COINBASE</p>
        </div>
        <div className=" space-x-2 border rounded-full border-[#D1A956] flex justify-center items-center py-2  w-full">
            <img className=" w-6" src={portis} alt="portis"/>
            <p>PORTIS</p>
        </div>
        <div className=" space-x-2 border rounded-full border-[#D1A956] flex justify-center items-center py-2  w-full">
            <img className=" w-6" src={crypto} alt="crypto"/>
            <p>CRYPTO.COM</p>
        </div>
      </div>
    </div>
    </TemplatePage>
  );
};

export default ConnectedDapp;
