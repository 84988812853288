import {useStores} from "../../context/StoreContext";
import {useNavigate} from "react-router-dom";

const intro_video_hash = `${process.env.REACT_APP_NFT_INTRO_VIDEO}`;
const intro_video_url = "https://www.youtube.com/embed/K4gINWH0tYI?autoplay=1&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&origin=https://innercircle.finance\n";//GetIPFSBaseURL(intro_video_hash); ////
const VideoPage = () => {
    const {uiStore} = useStores();
    const navigator = useNavigate();
    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         uiStore.setIsVideoPlayed(true);
    //         navigator("/");
    //     }, 1000*26);
    //     return () => clearTimeout(timer);
    // }, []);
    return (
        <div className={"h-screen w-screen relative bg-black"}>
            <div className={"w-screen h-screen absolute flex flex-col justify-center bg-black"}>
                <iframe
                    style={{width: "100%", height: "85%"}}
                    src={intro_video_url}
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>

            <div className={"text-white absolute z-40 bottom-5 left-0 right-0 text-center font-bold"}
                 onClick={() => {
                     uiStore.setCountDownFinished(true);
                     uiStore.setIsVideoPlayed(true);
                     navigator("/");
                 }}
            >
                <a className={"text-xl text-gold cursor-pointer hover:text-opacity-20 transition-all duration-500 ease-in-out"}>Enter the Inner Circle</a>
            </div>
        </div>
    );
}
export default VideoPage;