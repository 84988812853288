import RootStore from "./RootStore";
import {action, autorun, computed, makeAutoObservable, observable} from "mobx";
import {providers} from 'ethers';

export default class AccountStore {
    @observable
    account: any;

    @observable
    accountBalance: number = 0;
    @observable
    tokenBalance: number = 0;


    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
        autorun(() => {
            // if (!this.signClient) {
            //     this.init().catch(console.error);
            // }
        })
    }

    async init() {

    }


    @action
    onChangeAccount(accounts: any[]) {
        this.account = accounts[0]
        this.updateAccountBalances()
    }


    @action
    async getAccountBalance() {
        if (this.account) {
            // const balance = (await this.provider!.getBalance(this.account));
            // this.accountBalance = parseFloat(balance.toString()) / 1e18;
        }
    }

    @action
    async getEVITokenBalance() {
        if (this.account) {
            // const signer = this.provider!.getSigner();
            // const token = new ethers.Contract(this.tokenAddress, EVITokenJson.abi, signer);
            // const balance = (await token.balanceOf(this.account));
            // this.tokenBalance = parseFloat(balance.toString()) / 1e18;
        }
    }


    @action
    async disconnect() {
        // await this.rootStore.walletStore.disconnect()
    }


    @action
    updateAccountBalances() {
        this.getAccountBalance().catch(console.error);
        this.getEVITokenBalance().catch(console.error);
    }

    @action
    async login() {


        // const accounts = await window.ethereum.request({
        //     method: "eth_requestAccounts"
        // });
        // console.log(accounts)
        // if (accounts.length > 0) {
        //     console.log("Account connected", accounts);
        //     this.account = accounts[0];
        // }
    }
}