import Platinum from "../../assets/nft/Platinum.gif";
import PlatinumSold from "../../assets/images/platinum.png";
import Gold from "../../assets/nft/Gold.gif";
import GoldSold from "../../assets/images/gold.png";
import Silver from "../../assets/nft/Silver.gif";
import SilverSold from "../../assets/images/silver.png";
import Bronze from "../../assets/nft/Bronze.gif";
import BronzeSold from "../../assets/images/bronze.png";
import {ethers} from "ethers/lib.esm";
import {BigNumber} from "ethers";

export enum TokenType {
    PLATINUM = 0,
    GOLD = 1,
    SILVER = 2,
    BRONZE = 3,
    NONE = 4
}


export const getKeyImage = (tokenType: TokenType, isSoldOut?: boolean) => {
    switch (tokenType) {
        case TokenType.PLATINUM:
            return isSoldOut ? PlatinumSold : Platinum
        case TokenType.GOLD:
            return isSoldOut ? GoldSold : Gold
        case TokenType.SILVER:
            return isSoldOut ? SilverSold : Silver
        case TokenType.BRONZE:
            return isSoldOut ? BronzeSold : Bronze
    }
}
export const getKeyName = (tokenType: TokenType) => {
    switch (tokenType) {
        case TokenType.PLATINUM:
            return "PLATINUM"
        case TokenType.GOLD:
            return "GOLD"
        case TokenType.SILVER:
            return "SILVER"
        case TokenType.BRONZE:
            return "BRONZE"
    }
}
export const getKeyVideoHash = (tokenType: TokenType) => {
    switch (tokenType) {
        case TokenType.PLATINUM:
            return `${process.env.REACT_APP_NFT_PLATINUM_KEY_VIDEO}`
        case TokenType.GOLD:
            return `${process.env.REACT_APP_NFT_GOLD_KEY_VIDEO}`
        case TokenType.SILVER:
            return `${process.env.REACT_APP_NFT_SILVER_KEY_VIDEO}`
        case TokenType.BRONZE:
            return `${process.env.REACT_APP_NFT_BRONZE_KEY_VIDEO}`
    }
}
export const getKeyImageHash = (tokenType: TokenType) => {
    switch (tokenType) {
        case TokenType.PLATINUM:
            return `${process.env.REACT_APP_NFT_PLATINUM_KEY_IMAGE}`
        case TokenType.GOLD:
            return `${process.env.REACT_APP_NFT_GOLD_KEY_IMAGE}`
        case TokenType.SILVER:
            return `${process.env.REACT_APP_NFT_SILVER_KEY_IMAGE}`
        case TokenType.BRONZE:
            return `${process.env.REACT_APP_NFT_BRONZE_KEY_IMAGE}`
    }
}

export class TokenDetail {
    tokenId?: number;
    owner?: string;
    tokenType?: TokenType;
    price?: number;

    constructor(obj?: Partial<TokenDetail>) {
        Object.assign(this, obj);
    }

    static fromJson(json: any): TokenDetail {
        return new TokenDetail({
            tokenId: json.tokenId,
            owner: json.owner,
            tokenType: json.tokenType,
            price: json.price
        })
    }
}

export class TokenDetailList {
    // TokenDetail[] tokens;

    tokens: TokenDetail[] = [];

    constructor(obj?: Partial<TokenDetailList>) {
        Object.assign(this, obj);
    }

    tokenCount(type: TokenType) {
        return this.tokens?.filter(token => token.tokenType === type).length || 0;
    }

    tokenCost(type: TokenType) {
        let val = BigNumber.from(0);

        for (const filterElement of this.tokens?.filter(token => token.tokenType === type)) {
            val = val.add(BigNumber.from(filterElement.price));
        }

        return parseInt(`${parseFloat(ethers.utils.formatEther(val).toString()) * 1000}`) / 1000;
    }
}