import React, {useEffect, useState} from 'react';
import {StoreProvider} from "../context/StoreContext";
import RootStore from "context/store/RootStore";

import {Chain, configureChains, createClient, useSwitchNetwork, WagmiConfig} from 'wagmi'
import {publicProvider} from 'wagmi/providers/public'
import AuthRoutes from "../routes/AuthRoutes";
import {MetaMaskConnector} from 'wagmi/connectors/metaMask'

import {CoinbaseWalletConnector} from 'wagmi/connectors/coinbaseWallet'
import {InjectedConnector} from 'wagmi/connectors/injected'
import {WalletConnectConnector} from 'wagmi/connectors/walletConnect'

const networkID = `${process.env.REACT_APP_NETWORK_ID}`;
// const cronoTestChain: Chain = {
//     id: 338,
//     name: 'cronoTest',
//     network: 'cronosTestnet',
//     nativeCurrency: {
//         decimals: 18,
//         name: 'CRONO',
//         symbol: 'CRO',
//     },
//     rpcUrls: {
//         default: "https://evm-t3.cronos.org",
//     },
//     blockExplorers: {
//         default: {name: 'SnowTrace', url: 'https://snowtrace.io'},
//     },
//     testnet: true,
// }

const CornoMainNet: Chain = {
    id: parseInt(networkID),
    name: networkID === '338' ? 'cronosTestnet' : 'cronos',
    network: networkID === '338' ? 'cronosTestnet' : 'cronos',
    nativeCurrency: {
        decimals: 18,
        name: 'CRONO',
        symbol: 'CRO',
    },
    // rpcUrls: {
    //     default: "https://evm-t3.cronos.org",
    // },
    rpcUrls: {
        default: networkID === "338" ? "https://evm-t3.cronos.org" : "https://nd-644-151-554.p2pify.com/2d82448c04260ea8eca685ee821bee7a",
    },
    blockExplorers: {
        default: {name: 'SnowTrace', url: 'https://snowtrace.io'},
    },
    testnet: networkID === "338",
}

const {chains, provider} = configureChains([CornoMainNet], [publicProvider()])


const client = createClient({
    autoConnect: true,
    provider: provider,
    // connectors: [
    //     new MetaMaskConnector(),
    // ]
    connectors: [
        new MetaMaskConnector({chains}),
        new CoinbaseWalletConnector({
            chains,
            options: {
                appName: 'INNER CIRCLE',
            },
        }),
        new WalletConnectConnector({
            chains,
            options: {
                qrcode: true,
            },
        }),
        new InjectedConnector({
            chains,
            options: {
                name: 'Injected',
                shimDisconnect: true,
            },
        }),
    ],
})

function App() {
    const [rootStore] = useState(new RootStore());
    return (
        <>
            <WagmiConfig client={client}>
                <StoreProvider store={rootStore}>
                    <AuthRoutes/>
                </StoreProvider>
            </WagmiConfig>
        </>
    );
}

export default App;
