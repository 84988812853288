import TemplatePage from "./TemplatePage";
import KeyCard from "components/KeyCard";
import {TokenType} from "../context/data/TokenData";

const MintPage = () => {
    return (
        <TemplatePage showMenu={true}>
            <div className="flex space-x-4 flex-col mt-16 w-full px-16">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    <KeyCard tokenType={TokenType.PLATINUM}/>
                    <KeyCard tokenType={TokenType.GOLD}/>
                    <KeyCard tokenType={TokenType.SILVER}/>
                    <KeyCard tokenType={TokenType.BRONZE}/>
                </div>
            </div>
        </TemplatePage>
    )
}

export default MintPage