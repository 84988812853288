import {getKeyImageHash, getKeyName, getKeyVideoHash, TokenDetail, TokenDetailList, TokenType} from "../data/TokenData";
import {useAccount, useContractEvent, useContractRead} from "wagmi";
import {
    getContractBasicConfig,
    getContractV1BasicConfig, MINT_BRONZE_ADDRESS, MINT_GOLD_ADDRESS, MINT_PLATINUM_ADDRESS, MINT_SILVER_ADDRESS,
    MINT_V1_BRONZE_ADDRESS,
    MINT_V1_GOLD_ADDRESS,
    MINT_V1_PLATINUM_ADDRESS,
    MINT_V1_SILVER_ADDRESS
} from "../transaporter/MintDataTransporter";
import {useEffect, useState} from "react";
import {BigNumber} from "ethers";
import {fetchBalance, prepareWriteContract, readContract, writeContract} from '@wagmi/core'
import {ethers} from "ethers/lib.esm";
import {IPFS_URL, UploadIPFS} from "../transaporter/IPFSTransactions";
import {useStores} from "../StoreContext";

const CRO_DECIMALS = 10 ** 6;


export const useMintContract = () => {
    const {address} = useAccount();
    const {mintStore} = useStores();

    const mint = async (type: TokenType, numberOfNfts: number) => {
        mintStore.setIsMinting(type);
        const ContractBasicConfig: any = getContractBasicConfig(type);
        const metadata = {
            name: getKeyName(type),
            image: `${IPFS_URL}/${getKeyImageHash(type)}`,
            animation_url: `${IPFS_URL}/${getKeyVideoHash(type)}`,
            imageHash: `${getKeyImageHash(type)}`,
            description: getKeyName(type),
            compiler: "syigen-nft-generator",
            type: "video",
            attributes: [
                {
                    name: "type",
                    type: getKeyName(type),
                }
            ],
            generatedDate: new Date().toISOString()
        }

        const nftHash = await UploadIPFS(new Blob([JSON.stringify(metadata)]), "metadata.json");


        const nftTypePrice = await readContract({
            ...ContractBasicConfig,
            functionName: "getNFTTypePrice",
        })

        const croUsdPriceRead: any = await readContract({
            ...ContractBasicConfig,
            functionName: "getCurrentCroUSDPrice",
        });

        const [croUsdPrice] = croUsdPriceRead as [BigNumber, BigNumber];

        // const getPrice = (BigNumber.from(nftTypePrice || 0)).mul(BigNumber.from(numberOfNfts));
        const mintPrice = BigNumber.from(nftTypePrice).mul(numberOfNfts).div(croUsdPrice).mul(CRO_DECIMALS);
        const finalMintPrice = mintPrice.add(mintPrice.mul(1).div(1000));
        try {
            const config: any = await prepareWriteContract({
                ...ContractBasicConfig,
                functionName: 'safeMint',
                args: [address, nftHash, numberOfNfts],
                overrides: {
                    value: finalMintPrice,
                    // maxFeePerGas: 250000000000000,
                    // maxPriorityFeePerGas: 250000000000000,
                    gasLimit: 2000000
                }
            })
            const {hash, wait} = await writeContract(config)
            await wait()
        } finally {
            mintStore.setIsMinting(TokenType.NONE);
        }

    }
    return {mint}
}


interface GetNFTMaxcountProps {
    type: TokenType
    forceUpdate?: () => void
}

export const useGetMaxCount = ({type}: GetNFTMaxcountProps) => {
    const {data, isError, isLoading} = useContractRead({
        ...getContractBasicConfig(type),
        functionName: "getNFTTypeLimit",
    })
    const [maxMint, setMaxMint] = useState(0);
    useEffect(() => {
        if (data) {
            setMaxMint(BigNumber.from(data).toNumber())
        }
    }, [data])
    return {maxMint, isError, isLoading}
}

export const useGetAvailableCount = ({type, forceUpdate}: GetNFTMaxcountProps) => {


    const [availableMint, setAvailableMint] = useState(0);
    const getUpdate = async () => {
        const nFTTypeRemaining = await readContract({
            ...getContractBasicConfig(type),
            functionName: "getNFTTypeRemaining",
        })
        if (nFTTypeRemaining) {
            setAvailableMint(BigNumber.from(nFTTypeRemaining).toNumber())
        }
        return 0;
    }

    useMintListener((data: any) => {
        getUpdate().then(console.log).catch(console.error);
    }, type)

    useEffect(() => {
        // console.log('useGetAvailableCount', type);
        getUpdate().then(console.log).catch(console.error);
    }, [])
    return {availableMint}
}
export const useNFTTypePrice = ({type}: GetNFTMaxcountProps) => {

    const {data, isError, isLoading} = useContractRead({
        ...getContractBasicConfig(type),
        functionName: "getNFTTypePrice",
    })

    const [price, setPrice] = useState(0);
    useEffect(() => {
        if (data) {
            const val = parseFloat(ethers.utils.formatEther(BigNumber.from(data)));
            setPrice(val)
        }
    }, [data])
    return {price, isError, isLoading}
}

export const useGetCROUSDPrice = (type: TokenType) => {
    const [price, setPrice] = useState(0);
    const {data, isError, isLoading} = useContractRead({
        ...getContractBasicConfig(type),
        functionName: "getCurrentCroUSDPrice",
    })
    useEffect(() => {
        if (data) {
            const [croUsdPrice, time] = data as [BigNumber, BigNumber];
            setPrice(croUsdPrice.toNumber())
        }
    }, [data])

    return [price]
}

export const useNFTTypeCROPrice = ({type}: GetNFTMaxcountProps) => {
    const [croUsdPrice] = useGetCROUSDPrice(type);
    const [croPrice, setPrice] = useState(0);
    const {data, isError, isLoading} = useContractRead({
        ...getContractBasicConfig(type),
        functionName: "getNFTTypePrice",
    })


    useEffect(() => {
        if (croUsdPrice && data) {
            setPrice(parseFloat(ethers.utils.formatEther(BigNumber.from(data).mul(CRO_DECIMALS).div(croUsdPrice))))
        }
    }, [croUsdPrice, data])
    return {croPrice, isError, isLoading}
}

export const useNFTTypeTotalMinted = ({type}: GetNFTMaxcountProps) => {

    const {croPrice} = useNFTTypeCROPrice({type});

    const usdPrice = useContractRead({
        ...getContractBasicConfig(type),
        functionName: "getNFTTypePrice",
    })

    const {data, isError, isLoading} = useContractRead({
        ...getContractBasicConfig(type),
        functionName: "getNFTTypeCount",
    })


    const [totalMinted, setTotalMinted] = useState(0);
    const [totalUsdValue, setTotalUsdValue] = useState(0);
    const [totalCroValue, setTotalCroValue] = useState(0);
    useEffect(() => {
        if (data) {
            setTotalMinted(BigNumber.from(data).toNumber())
        }
    }, [data])
    useEffect(() => {
        if (data && croPrice) {
            const _croPrice = ethers.utils.parseEther(croPrice.toString());
            setTotalCroValue(parseFloat(ethers.utils.formatEther(BigNumber.from(data).mul(_croPrice)).toString()))
        }
    }, [data, croPrice])
    useEffect(() => {
        if (data && usdPrice && usdPrice.data) {
            setTotalUsdValue(parseFloat(ethers.utils.formatEther(BigNumber.from(data).mul(BigNumber.from(usdPrice.data))).toString()))
        }
    }, [data, usdPrice])


    return {totalMinted, totalUsdValue, totalCroValue, isError, isLoading}
}
export const getAddress = async () => {
    let treasuryAddress = '';
    let vaultAddress = '';
    let teamAddress = '';
    let marketingAddress = '';

    let res = await readContract({
        ...getContractV1BasicConfig(TokenType.GOLD),
        functionName: "getTreasuryAddress",
    })
    if (res) {
        console.log('getTreasuryAddress', res);
    }
    return {treasuryAddress, vaultAddress, teamAddress, marketingAddress}
}
export const getWalletBalance = async () => {
    let treasuryBalance = 0;
    let vaultBalance = 0;
    let teamBalance = 0;
    let marketingBalance = 0;

    let res = await fetchBalance({
        address: '0x000000',
    });
    return {treasuryBalance, vaultBalance, teamBalance, marketingBalance}
}
export const useDistributorsAddress = () => {
    const type = TokenType.PLATINUM;
    const [treasury, setTreasury] = useState<string>("");
    const [vault, setVault] = useState<string>("");
    const [marketing, setMarketing] = useState<string>("");
    const [team, setTeam] = useState<string>("");

    const treasuryAddress = useContractRead({
        ...getContractBasicConfig(type),
        functionName: "getTreasuryAddress"
    });
    const vaultAddress = useContractRead({
        ...getContractBasicConfig(type),
        functionName: "getVaultAddress"
    });
    const marketingAddress = useContractRead({
        ...getContractBasicConfig(type),
        functionName: "getMarketingAddress"
    });
    const teamAddress = useContractRead({
        ...getContractBasicConfig(type),
        functionName: "getTeamAddress"
    });

    useEffect(() => {
        if (treasuryAddress && treasuryAddress.data) {
            setTreasury(`${treasuryAddress.data}`)
        }

        if (vaultAddress && vaultAddress.data) {
            setVault(`${vaultAddress.data}`)
        }

        if (marketingAddress && marketingAddress.data) {
            setMarketing(`${marketingAddress.data}`)
        }

        if (teamAddress && teamAddress.data) {
            setTeam(`${teamAddress.data}`)
        }

    }, [treasuryAddress, vaultAddress, marketingAddress, teamAddress])
    return {treasury, vault, marketing, team}
}


export const useDistributorsValue = () => {
    const [treasuryAmount, setTreasuryAmount] = useState(0);
    const [vaultAmount, setVaultAmount] = useState(0);
    const [marketingAmount, setMarketingAmount] = useState(0);
    const [teamAmount, setTeamAmount] = useState(0);

    const platinumDist = useDistributorsValueInternal(TokenType.PLATINUM);
    const goldDist = useDistributorsValueInternal(TokenType.GOLD);
    const silverDist = useDistributorsValueInternal(TokenType.SILVER);
    const bronzeDist = useDistributorsValueInternal(TokenType.BRONZE);

    useEffect(() => {
        setTreasuryAmount((platinumDist.treasuryAmount || 0) + (goldDist.treasuryAmount || 0) + (silverDist.treasuryAmount || 0) + (bronzeDist.treasuryAmount || 0))
        setVaultAmount((platinumDist.vaultAmount || 0) + (goldDist.vaultAmount || 0) + (silverDist.vaultAmount || 0) + (bronzeDist.vaultAmount || 0))
        setMarketingAmount((platinumDist.marketingAmount || 0) + (goldDist.marketingAmount || 0) + (silverDist.marketingAmount || 0) + (bronzeDist.marketingAmount || 0))
        setTeamAmount((platinumDist.teamAmount || 0) + (goldDist.teamAmount || 0) + (silverDist.teamAmount || 0) + (bronzeDist.teamAmount || 0))
    }, [platinumDist, goldDist, silverDist, bronzeDist])

    return {treasuryAmount, vaultAmount, marketingAmount, teamAmount}
}

export const useCROUSDPrice = () => {
    const [croUsdPrice, setCroUsdPrice] = useState(0);
    const {data, isError, isLoading} = useContractRead({
        ...getContractBasicConfig(TokenType.PLATINUM),
        functionName: "getCurrentCroUSDPrice",
    })

    useEffect(() => {
        if (data) {
            setCroUsdPrice(parseFloat(`${data}`) / 10e5);
        }
    }, [data]);

    return {croUsdPrice, isError, isLoading}
}

export const useDistributorsValueOriginal = () => {
    const [treasuryAmount, setTreasuryAmount] = useState(0);
    const [vaultAmount, setVaultAmount] = useState(0);
    const [marketingAmount, setMarketingAmount] = useState(0);
    const [teamAmount, setTeamAmount] = useState(0);

    const {treasury, vault, marketing, team} = useDistributorsAddress();

    const {croUsdPrice} = useCROUSDPrice();


    useEffect(() => {
        if (treasury) {
            fetchBalance({
                address: `0x${`${treasury}`.replace('0x', '')}`,
            }).then((res) => {
                setTreasuryAmount(parseFloat(ethers.utils.formatEther(res.value).toString()) * croUsdPrice)
            })
        }
    }, [treasury, croUsdPrice])

    useEffect(() => {
        if (vault) {
            fetchBalance({
                address: `0x${`${vault}`.replace('0x', '')}`,
            }).then((res) => {
                setVaultAmount(parseFloat(ethers.utils.formatEther(res.value).toString()) * croUsdPrice)
            })
        }
    }, [vault, croUsdPrice])


    useEffect(() => {
        if (marketing) {
            fetchBalance({
                address: `0x${`${marketing}`.replace('0x', '')}`,
            }).then((res) => {
                setMarketingAmount(parseFloat(ethers.utils.formatEther(res.value).toString()) * croUsdPrice)
            })
        }
    }, [marketing, croUsdPrice])


    useEffect(() => {
        if (treasury) {
            fetchBalance({
                address: `0x${`${treasury}`.replace('0x', '')}`,
            }).then((res) => {
                setTeamAmount(parseFloat(ethers.utils.formatEther(res.value).toString()) * croUsdPrice)
            })
        }
    }, [treasury, croUsdPrice])


    return {treasuryAmount, vaultAmount, marketingAmount, teamAmount}
}
export const useDistributorsValueInternal = (type: TokenType) => {
    const {treasury, vault, marketing, team} = useDistributorsAddress();

    const treasuryValue = useContractRead({
        ...getContractBasicConfig(type),
        functionName: "getDistributionAmount",
        args: [treasury]
    });

    const vaultValue = useContractRead({
        ...getContractBasicConfig(type),
        functionName: "getDistributionAmount",
        args: [vault]
    });

    const marketingValue = useContractRead({
        ...getContractBasicConfig(type),
        functionName: "getDistributionAmount",
        args: [marketing]
    });

    const teamValue = useContractRead({
        ...getContractBasicConfig(type),
        functionName: "getDistributionAmount",
        args: [team]
    });

    const [treasuryAmount, setTreasuryAmount] = useState(0);
    const [vaultAmount, setVaultAmount] = useState(0);
    const [marketingAmount, setMarketingAmount] = useState(0);
    const [teamAmount, setTeamAmount] = useState(0);

    useEffect(() => {
        if (treasuryValue && treasuryValue.data) {
            setTreasuryAmount(parseFloat(ethers.utils.formatEther(BigNumber.from(treasuryValue.data)).toString()))
        }

    }, [treasuryValue]);

    useEffect(() => {
        if (vaultValue && vaultValue.data) {
            setVaultAmount(parseFloat(ethers.utils.formatEther(BigNumber.from(vaultValue.data)).toString()))
        }
    }, [vaultValue]);

    useEffect(() => {
        if (marketingValue && marketingValue.data) {
            setMarketingAmount(parseFloat(ethers.utils.formatEther(BigNumber.from(marketingValue.data)).toString()))
        }
    }, [marketingValue]);

    useEffect(() => {
        if (teamValue && teamValue.data) {
            setTeamAmount(parseFloat(ethers.utils.formatEther(BigNumber.from(teamValue.data)).toString()))
        }
    }, [teamValue]);
    return {treasuryAmount, vaultAmount, marketingAmount, teamAmount}
}

export const useNFTTypeSharePercentage = ({type}: GetNFTMaxcountProps) => {
    const {maxMint} = useGetMaxCount({type});
    const {availableMint} = useGetAvailableCount({type});

    const [sharePercentage, setSharePercentage] = useState(0);
    useEffect(() => {
        if (maxMint && availableMint) {
            setSharePercentage(((maxMint - availableMint) / maxMint) * 100)
        }
    }, [maxMint, availableMint])
    return {sharePercentage}
}

export const useNFTDistribution = () => {
    const platinumPercentage = useNFTTypeSharePercentage({type: TokenType.PLATINUM});
    const goldPercentage = useNFTTypeSharePercentage({type: TokenType.GOLD});
    const silverPercentage = useNFTTypeSharePercentage({type: TokenType.SILVER});
    const bronzePercentage = useNFTTypeSharePercentage({type: TokenType.BRONZE});

    const [platinumPercentageValue, setPlatinumPercentageValue] = useState(0);
    const [goldPercentageValue, setGoldPercentageValue] = useState(0);
    const [silverPercentageValue, setSilverPercentageValue] = useState(0);
    const [bronzePercentageValue, setBronzePercentageValue] = useState(0);

    useEffect(() => {
        if (platinumPercentage && platinumPercentage.sharePercentage) {
            setPlatinumPercentageValue(platinumPercentage.sharePercentage)
        }
    }, [platinumPercentage])

    useEffect(() => {
        if (goldPercentage && goldPercentage.sharePercentage) {
            setGoldPercentageValue(goldPercentage.sharePercentage)
        }
    }, [goldPercentage])

    useEffect(() => {
        if (silverPercentage && silverPercentage.sharePercentage) {
            setSilverPercentageValue(silverPercentage.sharePercentage)
        }
    }, [silverPercentage])

    useEffect(() => {
        if (bronzePercentage && bronzePercentage.sharePercentage) {
            setBronzePercentageValue(bronzePercentage.sharePercentage)
        }
    }, [bronzePercentage])


    return {
        platinumPercentageValue,
        goldPercentageValue,
        silverPercentageValue,
        bronzePercentageValue
    }
}

export const useOwnedTokensDetails = () => {
    const [ownedPTTokens, setOwnedPtTokens] = useState<TokenDetail[]>([]);
    const [ownedGDTokens, setOwnedGdTokens] = useState<TokenDetail[]>([]);
    const [ownedSITokens, setOwnedSiTokens] = useState<TokenDetail[]>([]);
    const [ownedBRTokens, setOwnedBrTokens] = useState<TokenDetail[]>([]);

    const [ptOwnedTokens, setPtOwnedTokens] = useState<any>([]);
    const [gdOwnedTokens, setGdOwnedTokens] = useState<any>([]);
    const [slOwnedTokens, setSlOwnedTokens] = useState<any>([]);
    const [brOwnedTokens, setBrOwnedTokens] = useState<any>([]);

    const {address} = useAccount();

    const [ownedTokens, setOwnedTokens] = useState<TokenDetailList>();


    useEffect(() => {
        readContract({
            ...getContractBasicConfig(TokenType.PLATINUM),
            functionName: "getOwnedTokens",
            args: [address]
        }).then((data: any) => {
            // console.log("PLATINUM", data)
            setPtOwnedTokens(data)
        }).catch(console.log);
        readContract({
            ...getContractBasicConfig(TokenType.GOLD),
            functionName: "getOwnedTokens",
            args: [address]
        }).then((data: any) => {
            // console.log("GOLD", data)
            setGdOwnedTokens(data)
        }).catch(console.log);
        readContract({
            ...getContractBasicConfig(TokenType.SILVER),
            functionName: "getOwnedTokens",
            args: [address]
        }).then((data: any) => {
            // console.log("SILVER", data)
            setSlOwnedTokens(data)
        }).catch(console.log);
        readContract({
            ...getContractBasicConfig(TokenType.BRONZE),
            functionName: "getOwnedTokens",
            args: [address]
        }).then((data: any) => {
            // console.log("BRONZE", data)
            setBrOwnedTokens(data)
        }).catch(console.log);
    }, [address])

    useEffect(() => {
        if (ptOwnedTokens) {
            setOwnedPtTokens((ptOwnedTokens as any).map((token: any) => {
                const t = TokenDetail.fromJson(token)
                t.tokenType = TokenType.PLATINUM;
                return t
            }))
        }
    }, [ptOwnedTokens])

    useEffect(() => {
        if (gdOwnedTokens) {
            setOwnedGdTokens((gdOwnedTokens as any).map((token: any) => {
                const t = TokenDetail.fromJson(token)
                t.tokenType = TokenType.GOLD;
                return t
            }))
        }
    }, [gdOwnedTokens])
    useEffect(() => {
        if (slOwnedTokens) {
            setOwnedSiTokens((slOwnedTokens as any).map((token: any) => {
                const t = TokenDetail.fromJson(token)
                t.tokenType = TokenType.SILVER;
                return t
            }))
        }
    }, [slOwnedTokens])
    useEffect(() => {
        if (brOwnedTokens) {
            setOwnedBrTokens((brOwnedTokens as any).map((token: any) => {
                const t = TokenDetail.fromJson(token)
                t.tokenType = TokenType.BRONZE;
                return t
            }))
        }
    }, [brOwnedTokens])


    useEffect(() => {
        setOwnedTokens(new TokenDetailList({
            tokens: [
                ...ownedPTTokens,
                ...ownedGDTokens,
                ...ownedSITokens,
                ...ownedBRTokens
            ]
        }))
    }, [ownedPTTokens, ownedGDTokens, ownedSITokens, ownedBRTokens])


    return {ownedTokens};
}

export const useMintListener = (onMint: (_any: any) => void, type: TokenType) => {
    useContractEvent({
        ...getContractBasicConfig(type),
        eventName: 'NFTMinted',
        listener(node: any, label: any, owner: any) {
            if (onMint) {
                onMint({node, label, owner})
            }
        },
    })
}


export const useOwnedV1TokensDetails = () => {
    const [ownedPTTokens, setOwnedPtTokens] = useState<TokenDetail[]>([]);
    const [ownedGDTokens, setOwnedGdTokens] = useState<TokenDetail[]>([]);
    const [ownedSITokens, setOwnedSiTokens] = useState<TokenDetail[]>([]);
    const [ownedBRTokens, setOwnedBrTokens] = useState<TokenDetail[]>([]);

    const {address} = useAccount();


    const ptOwnedTokens = useContractRead({
        ...getContractV1BasicConfig(TokenType.PLATINUM),
        functionName: "getOwnedTokens",
        args: [address]
    });
    const gdOwnedTokens = useContractRead({
        ...getContractV1BasicConfig(TokenType.GOLD),
        functionName: "getOwnedTokens",
        args: [address]
    });
    const slOwnedTokens = useContractRead({
        ...getContractV1BasicConfig(TokenType.SILVER),
        functionName: "getOwnedTokens",
        args: [address]
    });
    const brOwnedTokens = useContractRead({
        ...getContractV1BasicConfig(TokenType.BRONZE),
        functionName: "getOwnedTokens",
        args: [address]
    });

    useEffect(() => {
        if (ptOwnedTokens && ptOwnedTokens.data) {
            setOwnedPtTokens((ptOwnedTokens.data as any).map((token: any) => {
                const t = TokenDetail.fromJson(token)
                t.tokenType = TokenType.PLATINUM;
                return t
            }))
        }
    }, [ptOwnedTokens.data])

    useEffect(() => {
        if (gdOwnedTokens && gdOwnedTokens.data) {
            setOwnedGdTokens((gdOwnedTokens.data as any).map((token: any) => {
                const t = TokenDetail.fromJson(token)
                t.tokenType = TokenType.GOLD;
                return t
            }))
        }
    }, [gdOwnedTokens.data])
    useEffect(() => {
        if (slOwnedTokens && slOwnedTokens.data) {
            setOwnedSiTokens((slOwnedTokens.data as any).map((token: any) => {
                const t = TokenDetail.fromJson(token)
                t.tokenType = TokenType.SILVER;
                return t
            }))
        }
    }, [slOwnedTokens.data])
    useEffect(() => {
        if (brOwnedTokens && brOwnedTokens.data) {
            setOwnedBrTokens((brOwnedTokens.data as any).map((token: any) => {
                const t = TokenDetail.fromJson(token)
                t.tokenType = TokenType.BRONZE;
                return t
            }))
        }
    }, [brOwnedTokens.data])

    return new TokenDetailList({
        tokens: [
            ...ownedPTTokens,
            ...ownedGDTokens,
            ...ownedSITokens,
            ...ownedBRTokens
        ]
    });
}

export const useIsApprovedForSwap = (token: TokenType) => {
    const [isApproved, setIsApproved] = useState(false);
    const {address} = useAccount();
    const getContractAddressV2 = () => {
        switch (token) {
            case TokenType.PLATINUM:
                return MINT_PLATINUM_ADDRESS;
            case TokenType.GOLD:
                return MINT_GOLD_ADDRESS;
            case TokenType.SILVER:
                return MINT_SILVER_ADDRESS;
            case TokenType.BRONZE:
                return MINT_BRONZE_ADDRESS;
        }
    }
    const contractV2Address = getContractAddressV2();
    const {data, isError, isLoading} = useContractRead({
        ...getContractV1BasicConfig(token),
        functionName: "isApprovedForAll",
        args: [address, contractV2Address],
    })

    useEffect(() => {
        console.log("isApprovedForAll", token, contractV2Address, data)
        setIsApproved(data !== null && data !== undefined && data === true)
        // if (data) {
        //     console.log("isApprovedForAll", token, contractV2Address, data)
        // }
    }, [data]);

    return {isApproved, isError, isLoading}
}

export const useApprove = async (tokenType: TokenType) => {

    const approve = async () => {
        const getContractAddressV2 = () => {
            switch (tokenType) {
                case TokenType.PLATINUM:
                    return MINT_PLATINUM_ADDRESS;
                case TokenType.GOLD:
                    return MINT_GOLD_ADDRESS;
                case TokenType.SILVER:
                    return MINT_SILVER_ADDRESS;
                case TokenType.BRONZE:
                    return MINT_BRONZE_ADDRESS;
            }
        }
        const contractV2Address = getContractAddressV2();
        console.log("contractV2Address", contractV2Address)
        const approveConfig: any = await prepareWriteContract({
            ...getContractV1BasicConfig(tokenType),
            functionName: 'setApprovalForAll',
            args: [contractV2Address, true],
        })
        const approveTx = await writeContract(approveConfig)
        await approveTx.wait()
    }
    return {approve}
}

export const useSwapV1ToV2 = async (tokenType: TokenType) => {
    const {address} = useAccount();
    const swap = async () => {
        const metadata = {
            name: getKeyName(tokenType),
            image: `${IPFS_URL}/${getKeyImageHash(tokenType)}`,
            animation_url: `${IPFS_URL}/${getKeyVideoHash(tokenType)}`,
            imageHash: `${getKeyImageHash(tokenType)}`,
            description: getKeyName(tokenType),
            compiler: "syigen-nft-generator",
            type: "video",
            attributes: [
                {
                    name: "type",
                    type: getKeyName(tokenType),
                }
            ],
            generatedDate: new Date().toISOString()
        }

        const nftHash = await UploadIPFS(new Blob([JSON.stringify(metadata)]), "metadata.json");
        const getContractAddressV1 = () => {
            switch (tokenType) {
                case TokenType.PLATINUM:
                    return MINT_V1_PLATINUM_ADDRESS;
                case TokenType.GOLD:
                    return MINT_V1_GOLD_ADDRESS;
                case TokenType.SILVER:
                    return MINT_V1_SILVER_ADDRESS;
                case TokenType.BRONZE:
                    return MINT_V1_BRONZE_ADDRESS;
            }
        }

        const contractV1Address = getContractAddressV1();

        console.log("contractV1Address", contractV1Address)

        try {
            const config: any = await prepareWriteContract({
                ...getContractBasicConfig(tokenType),
                functionName: 'mintV1',
                args: [contractV1Address, address, nftHash],
                overrides: {
                    // maxFeePerGas: 250000000000000,
                    // maxPriorityFeePerGas: 250000000000000,
                    gasLimit: 2000000
                }
            })
            const {hash, wait} = await writeContract(config)
            await wait()
        } finally {

        }
    }
    return {swap}
}